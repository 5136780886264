.reports-container {
    position: relative;
    max-width: 1700px;
    margin: 0 auto;
    padding: 20px 0px;
}

.reports-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 8px;
    height: 70px;
  
    background-color: #EAEFF6;
    border-radius: 40px;
    color: #9096a2;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}



.base-style-btns-reports {
    padding: 14px 32px;
    color: #0a112f;
    background-color: inherit;
    border-radius: 40px;
    cursor: pointer;
    color: #9096a2;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 14px;
    border: 0px;
  }
  
  .active-btn-reports {
      color: #0a112f;
      background-color: #fff;
      border: 0px;
      border-radius: 40px;
      box-shadow: 0 0 18px rgba(192, 192, 209, 0.5);
  }
  
  .base-style-btns-reports:hover,
  .base-style-btns-reports:focus {
    color: #0a112f;
    background-color: #fff;
    border: 0px;
    border-radius: 40px;
    box-shadow: 0 0 18px rgba(192, 192, 209, 0.5);
  }

  .management-container-reports {
    position: absolute;
    top: 170px;
    left: -230px;
  }