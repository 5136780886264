.statistics-container {
  position: relative;
  max-width: 1700px;
  margin: 0 auto;
  padding: 20px 0px;
}

@media (max-width: 1199.98px) {
  .statistics-container {
    padding: 10px 0px;
    width: 100%;
  }
}

/* .statistics-buttons {
} */

@media (min-width: 1200px) {
  .statistics-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 8px;
    height: 70px;

    background-color: #eaeff6;
    border-radius: 40px;
    color: #9096a2;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }
  .statistics-buttons i {
    display: none;
  }
  .statistics-buttons p br {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .statistics-buttons {
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  .statistics-buttons p {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 10px;
  }

  /* .statistics-buttons button:not(:last-child) {
    border-right: 1px solid #799ac4cb;
  } */
}

/* .base-style-btns-statistics {
} */

@media (min-width: 1200px) {
  .base-style-btns-statistics {
    padding: 14px 32px;
    color: #0a112f;
    background-color: inherit;
    border-radius: 40px;
    cursor: pointer;
    color: #9096a2;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 14px;
    border: 0px;
  }
}

@media (max-width: 1199.98px) {
  .base-style-btns-statistics {
    height: 60px;
    padding: 0;
    flex: 1;
    border-radius: 0;
    border-top: 1px solid #29519c56;
    border-right: none;
    border-left: none;
    border-bottom: none;
  }
}

@media (max-width: 1199.98px) {
  .base-style-btns-statistics {
    color: #949494;
    background-color: #fff;
  }
}

/* .active-btn-reports-statistics {
} */

@media (min-width: 1200px) {
  .active-btn-statistics {
    color: #0a112f;
    background-color: #fff;
    border: 0px;
    border-radius: 40px;
    box-shadow: 0 0 18px rgba(192, 192, 209, 0.5);
  }
  .base-style-btns-statistics:hover,
  .base-style-btns-statistics:focus {
    color: #0a112f;
    background-color: #fff;
    border: 0px;
    border-radius: 40px;
    box-shadow: 0 0 18px rgba(192, 192, 209, 0.5);
  }
}

@media (max-width: 1199.98px) {
  .active-btn-statistics {
    color: #253164;
    background-color: #c9e0ff;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
  .base-style-btns-statistics:hover,
  .base-style-btns-statistics:focus {
    color: #253164;
    background-color: #c9e0ff;
  }
}

/** СТАТИСТИКА МЕДИКАМЕНТІВ */

@media (min-width: 1200px) {
  .st-med-container {
    max-width: 1700px;
    padding: 40px 0;
    display: flex;
    gap: 4px;
  }
}

@media (max-width: 1199.98px) {
  .st-med-container {
    display: flex;
    flex-direction: column;
  }
}

.st-med-sort-container {
  min-width: 230px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 1199.98px) {
  .st-med-sort-sub-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 100%;
  }

  .sort-sub-quantity {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

@media (min-width: 1200px) {
  .sort-sub-text {
    margin-top: 10px;
  }
}

@media (max-width: 1199.98px) {
  .sort-sub-text {
    font-weight: 400;
    font-size: 14px;
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .st-med-sort-container {
    padding: 0 4px;
    align-items: center;
  }
}

.select-value-page-med {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .select-value-page-med {
    margin-top: 8px;
    padding-left: 4px;
    height: 30px;
    width: 60px;
  }
}

@media (max-width: 1199.98px) {
  .select-value-page-med {
    height: 40px;
    font-size: 16px;
  }
}

.select-value-page-med option {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.table-st-med {
  flex: 1;
  max-width: 1200px;
}

@media (max-width: 1199.98px) {
  .table-st-med {
    padding: 0 4px 80px;
  }
}

.create-report-st-med-btn {
  width: 200px;
  min-height: 40px;

  cursor: pointer;

  background-color: #c4eee0;
  border: 2px solid #78d4a9;
  border-radius: 6px;
  box-shadow: -1px 1px 3px 1px #e0e0e0;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .create-report-st-med-btn {
    flex: 1;
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.create-report-st-med-btn:hover {
  color: #fff;
  background-color: #5ac5a1;
  border-color: #3da474;
}

.btn-calendar-st-med {
  cursor: pointer;

  background-color: #c6dfff;
  border: 2px solid #72aefd;
  border-radius: 6px;
  box-shadow: -1px 1px 3px 1px #e0e0e0;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

@media (min-width: 1200px) {
  .btn-calendar-st-med {
    margin-top: 16px;
    width: 200px;
    height: 40px;
  }
}

@media (max-width: 1199.98px) {
  .btn-calendar-st-med {
    height: 40px;
    width: 100%;
    font-size: 14px;
  }
}

.btn-calendar-st-med:hover {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

/* .st-med-sort-btns {
  display: flex;
  gap: 8px;
}

@media (min-width: 1200px) {
  .st-med-sort-btns {
    flex-direction: column;
  }
}

@media (max-width: 1199.98px) {
  .st-med-sort-btns {
    align-items: center;
    padding-bottom: 12px;
  }
} */

.size-table-st-med {
  width: 60%;
}
.size-table-st-med1 {
  width: 10%;
}

.size-table-st-med2 {
  width: 12%;
}

.table-st-med-title,
.table-st-med-total {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.table-st-med-unit {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.table-st-med-head th,
.table-st-cons-head th {
  padding: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .table-st-med-title,
  .table-st-med-total,
  .table-st-med-unit {
    font-size: 12px;
  }

  .table-st-med-head th,
  .table-st-cons-head th {
    padding: 4px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}

.size-table-st-cons {
  width: 7%;
}

.size-table-st-cons2 {
  width: 12%;
}

.table-st-cons-total {
  background-color: #c2c2c2;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.table-st-cons-total td {
  padding: 8px 6px;
  text-align: center;
}

.table-st-cons-total td:nth-child(2) {
  text-align: right;
}

@media (max-width: 1199.98px) {
  .table-st-cons-total td {
    padding: 4px;
    text-align: center;
    font-size: 12px;
  }
}

.table-st-cons-data {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.table-st-cons-data td {
  text-align: center;
}

@media (max-width: 1199.98px) {
  .table-st-cons-data td {
    padding: 4px;
    font-size: 12px;
  }
}

.data-receipt_number {
  text-align: center;
}

.cons-big-container-data-full {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .cons-big-container-data-full {
    font-size: 10px;
  }
}

.cons-full-data-size2 {
  max-width: 293px;
  word-wrap: break-word;
}

.cons-full-data-size1 {
  width: 26%;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .cons-full-data-size1 {
    width: 20%;
    text-align: center;
  }
}

.cons-full-data-size {
  width: 26%;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .cons-full-data-size {
    width: 24%;
  }
}

.st-cons-sort-container {
  display: flex;
  gap: 8px;
}

@media (min-width: 1200px) {
  .st-cons-sort-container {
    flex-direction: column;
  }
}

.cons-full-data-size3 {
  width: 5.8%;
  text-align: center;
}

.cons-full-data-size5 {
  text-align: center;
}

@media (min-width: 1200px) {
  .cons-full-data-size3 p:nth-child(2) {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .cons-full-data-size3 p:nth-child(1) {
    display: none;
  }
}

.cons-full-data-size4 {
  text-align: center;
}

@media (max-width: 1199.98px) {
  .cons-full-data-size4 {
    display: none;
  }
}

.cons-full-data-size4-mob {
  text-align: center;
}

@media (min-width: 1200px) {
  .cons-full-data-size4-mob {
    display: none;
  }
}

.cons-big-container-data-text1 {
  text-align: right;
}

@media (max-width: 1199.98px) {
  .cons-big-container-data-total {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
  }
}

.cons-big-container-data-text2 {
  text-align: center;
}

.cons-big-container-data-text-summ {
  text-align: right;
}

.cons-big-container-data-total {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  background-color: #c2c2c2;
}

@media (max-width: 1199.98px) {
  .cons-big-container-data-total {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
  }
}

@media (max-width: 1199.98px) {
  .cons-big-table-container {
    padding: 0 2px;
  }
}

@media (max-width: 1199.98px) {
  .cons-full-data-size-not {
    font-size: 8px;
  }
}

.cons-big-full-container {
  display: flex;
}

@media (min-width: 1200px) {
  .cons-big-full-container {
    max-width: 1700px;
    padding: 40px 0;
    gap: 4px;
  }
}

@media (max-width: 1199.98px) {
  .cons-big-full-container {
    flex-direction: column;
    padding: 80px 0;
  }
}

.btn-sort-full-info {
  height: 60px;
  width: 60px;
  border-top: 2px solid #6076929a;
  border-bottom: 2px solid #6076929a;
  border-left: 2px solid #6076929a;
  border-right: none;
  background-color: #f8f8f89f;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 40px;
  cursor: pointer;
  box-shadow: 0 0 6px 1px rgba(133, 133, 133, 0.582);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.btn-sort-full-info:hover,
.btn-sort-full-info:focus {
  color: #fff;
  background-color: #87d3b675;
}

.btn-sort-full-info i {
  color: #0000009d;
}

@media (min-width: 1200px) {
  .container-sort-full-info {
    display: none;
  }

  .container-sort-cons .cross {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .container-sort-full-info {
    position: fixed;
    top: 76px;
    right: -4px;
  }
}

@media (max-width: 1199.98px) {
  .container-sort-cons {
    display: none;
    overflow: hidden;
    transition: width 4s ease-in-out;
    width: 0;
  }

  .container-sort-cons.visible {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    padding: 80px 0 0;
    background-color: #f5f9fff1;
  }

  .container-sort-cons.visible .cross {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

