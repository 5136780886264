.admin-header {
  position: relative;
  background-color: #fafcff88;
}

@media (min-width: 1200px) {
  .admin-header {
    max-width: 1700px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.news-container-fixed-image {
  position: fixed;

  top: 18%;
  left: 44.3%;
  background-size: 50% auto;
  background-repeat: no-repeat;
  width: 30%;
  height: 70%;
  z-index: -1;
  background-image: url(../../public/images/logo-autorization.png);
  background-repeat: no-repeat;
}

@media (max-width: 1199.98px) {
  .news-container-fixed-image {
    display: none;
  }
}

.admin-container-btns {
  background-color: #d2dce9;
}

@media (min-width: 1200px) {
  .admin-container-btns {
    padding: 10px 15px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    border-radius: 6px;
  }
}

.admin-sub-container-nav {
  display: flex;
  align-items: center;
}

@media (max-width: 1199.98px) {
  .admin-sub-container-nav {
    justify-content: space-around;
    flex: 1;
  }
}

.admin-sub-container-btns {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .admin-sub-container-btns {
    flex: 1;
  }
}

@media (max-width: 1199.98px) {
  .admin-sub-container-btns {
    justify-content: center;
  }
}

.home-page-header-nav {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  color: #353637;
}

@media (min-width: 1200px) {
  .home-page-header-nav {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 2%;
    line-height: 26px;
  }
  .home-page-header-nav i {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .home-page-header-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-size: 10px;
    padding: 4px 0;
  }
}

.header-logo {
  height: 40px;
}

@media (max-width: 1199.98px) {
  .header-logo {
    display: none;
  }
}

.admin-btns {
  position: relative;
  background-color: inherit;
}

@media (min-width: 1200px) {
  .admin-btns {
    padding: 4px 14px;
    border: none;
  }
}

@media (max-width: 1199.98px) {
  .admin-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    flex: 1;
    border-right: 2px solid #607692;
    
  }
  .admin-btns-active {
    background-color: #c9e0ff;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
}

.admin-btns-active p {
  color: #1e4a88;
}

.admin-container-btns p::after {
  content: "";
  height: 2px;
  width: 0%;
  display: block;
  transition: 0.3s;
}

.admin-btns-active p::after,
.admin-container-btns p:hover::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #4c6991;
  display: block;
  transition: 0.3s;
}

@media (max-width: 1199.98px) {
  .admin-container-btns p::after {
    display: none;
  }

  .admin-btns-active p::after,
  .admin-container-btns p:hover::after {
    display: none;
  }
}

.admin-container-time {
  padding: 10px 10px;
  background-color: #597193ad;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 34px;
  border-radius: 6px;
  cursor: default;
}

@media (max-width: 1199.98px) {
  .admin-container-time {
    display: none;
  }
}

.admin-container-sub-cont {
  display: flex;
  align-items: baseline;
  gap: 12px;
}

@media (min-width: 1200px) {
  .admin-container-sub-cont {
    padding: 0 0 0 12px;
  }
}

.admin-container-icons {
  display: flex;
  color: #949494;
  align-items: center;
}

@media (max-width: 1199.98px) {
  .admin-container-icons {
    flex-direction: column;
    font-weight: 700;
    font-size: 10px;
    padding: 0 8px;
  }
}

.admin-container-icons i {
  cursor: pointer;
}

.admin-container-icons i:hover,
.admin-container-icons i:focus {
  color: #ffffff;
}

@media (min-width: 1200px) {
  .admin-container-icons p {
    display: none;
  }
}

.push-content-news {
  position: absolute;
  top: 0;
  right: 0;
  color: #f8f8f8;
  background-color: #ff3838;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 20px;
  padding-top: 3px;

  font-weight: 600;
  font-size: 12px;
}
