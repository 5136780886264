.modal-overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 8%;
}

.modal-content {
  position: relative;
  background-color: #f5f5f5;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 560px;
  text-align: center;
  z-index: 99;
}

@media (max-width: 1199.98px) {
  .modal-content {
    min-width: 320px;
    z-index: 99;
  }
}

.modal-content input {
  width: 100%;
  padding: 6px 6px;
  border: 1px solid #949494;
  border-radius: 6px;
}

.modal-content input::placeholder {
  color: #707070;
}

input[type="date"] {
  color: #707070; /* Замените на нужный вам цвет */
}

.modal-content label {
  width: 100%;
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.patient-item {
  display: flex;
  flex-direction: column;
}

.patient-list-info {
  padding: 8px;
  border: 2px solid #b1b1b1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.patient-list-info li {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.patients-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px; 
  overflow-y: auto;
  margin: 10px 0 0;
  padding: 0 0 20px;
}

.modal-search-title {
  margin: 4px 0 16px;

  text-align: center;
  font-family: "Montserrat", sans-serif;

  font-weight: 600;
  font-size: 18px;
}

.input-container {
  display: flex;
  gap: 10px;

}

.input-container label {
  width: 100%;
}

.btn-search {
  width: 120px;
  padding: 6px 20px;
  margin-top: 8px;
  background-color: #e0e0e0;
  border: 2px solid #afafaf;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.btn-search:hover {
  background-color: #c3ddff;
  border-color: #3981f7;
  color: #1a6ff8;
}

.btn-close-modal {
  position: absolute;
  right: 4px;
  top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  cursor: pointer;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
}

.btn-close-modal:hover {
  background-color: #e7e7e7;
  border: 2px solid #afafaf;
}

.btn-close-modal img {
  width: 20px;
  height: 20px;
}

.container-full-name {
  position: relative;
  /* flex: 1; */
  width: 100%;
}


.dropdown-full-name li {
  padding: 5px 5px;
  cursor: pointer;
  border-top: 1px solid #1d1d1d85;
  text-align: left;
}

.dropdown-full-name li:hover {
  background-color: #d0e4fa;
}

.dropdown-full-name {
  position: absolute;
  top: 100%;

  width: 100%;
  border: 1px solid #16161685;
  
  background-color: #fff;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;

  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
