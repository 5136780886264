.operations-dropdown {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  width: 47.88%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.confirm-modal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  background-color: white;
  padding: 24px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}

.confirm-modal p {
  font-family: "Montserrat", sans-serif;

  font-weight: 500;
  font-size: 16px;
}

.confirm-modal-btn-save {
  padding: 10px 0 0 0;
  display: flex;
  gap: 8px;
}

.confirm-modal-btn-save button {
  width: 60px;
  padding: 6px 6px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;

  font-weight: 500;
  font-size: 14px;
  border: 1px solid #444444;
  border-radius: 6px;
}

.confirm-modal button:nth-child(2):hover {
  background-color: #52db99;
  color: #fdfdfd;
}

.confirm-modal button:nth-child(1):hover {
  background-color: #ff5757b9;
  color: #fdfdfd;
}

.operations-dropdown li {
  padding: 5px 10px;
  cursor: pointer;
}

.operations-dropdown li:hover {
  background-color: #e6e6e6;
}
