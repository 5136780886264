.container-saved-forms {
  margin: 0 auto;
  max-width: 1700px;
  display: flex;
  gap: 4px;
}

.container-saved-forms td {
  padding: 4px 4px;
}

.calendar-saved-forms {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
}

.container-saved-forms-size {
  flex: 1;
}

.management-saved-forms {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 230px;
}

.btn-calendar {
  width: 200px;
  margin-top: 12px;
  height: 40px;

  cursor: pointer;

  background-color: #c6dfff;
  border: 2px solid #72aefd;
  border-radius: 6px;
  box-shadow: -1px 1px 3px 1px #e0e0e0;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.btn-calendar:hover {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.mini-form-info {
  margin: 30px 0 0 10px;
  max-width: 1181px;
  height: 30px;
}

.list-saved-forms {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 1200px;
  padding: 20px 0 100px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.item-saved-forms {
  display: flex;
  flex-direction: column;
  padding: 8px 8px;

  background-color: #f1f1f193;

  border: 2px solid #c4c4c4;
  border-radius: 10px;
}

.input-size-save-table {
  padding-left: 4px;
}

.item-saved-forms table {
  background-color: #ffffff;
}

.semi-title-color {
  background-color: #dfdfdf;
}

.table-save {
  margin-top: 12px;
}

.table-save input,
.table-save select,
.mini-form input {
  height: 28px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.table-save select {
  cursor: pointer;
}

.table-save thead {
 background-color: #ff0000;
}

.mini-form {
  display: flex;
  justify-content: space-between;
}

.table-save-size4 {
  width: 1%;
}

.table-save-size4.yellow {
  background-color: rgb(255, 248, 152);
}

.table-save-size4.green {
  background-color: #87d3b6;
}

.semi-mini-title-size {
  width: 1%;
}

.semi-mini-title-size1 {
  width: 12%;
}
.semi-mini-title-size2 {
  width: 15.1%;
}
.semi-mini-title-size3 {
  width: 12%;
}

.semi-mini-title-size4 {
  width: 9.1%;
}

.table-save-size {
  width: 7%;
  text-align: center;
}

.table-save-size1 {
  width: 4%;
}

.table-save-size2 {
  width: 5%;
  text-align: center;
}

.table-save-size3 {
  width: 35%;
}

.table-save-size5 {
  text-align: center;
}

.semititle-size1 {
  width: 12%;
}

.semititle-size1 p{
padding-left: 2px;
}

.semititle-size2 {
  width: 16%;
  text-align: center;
}

.semititle-size3 {
  width: 15.2%;
  text-align: center;
}

.semititle-size4 {
  width: 3.1%;
  cursor: pointer;
}

.semititle-size6 {
  text-align: center;
  width: 6%;
  cursor: pointer;
  background-color: #87d3b675;
}

.semititle-size4 div {
  display: flex;
  justify-content: center;
}

.semititle-size5 {
  text-align: center;
  padding-left: 8px;
  font-weight: 600;
}

.semititle-size6:hover {
  background-color: #87d3b6;
}

.semititle-size4:hover {
  background-color: #ccc;
}


.text-head-saved-forms {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.rotate-0 {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.text-size-saved-forms {
  padding: 8px 8px;
}

.check-static {
  text-align: center;
}

.autocomplete-container {
  position: relative;
  /* z-index: 2000; */
  width: 100%;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-left: 14.2%;
  width: 30.4%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 2;
  max-height: 150px;
  overflow-y: auto;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.autocomplete-dropdown li,
.autocomplete-dropdown-medicament li,
.autocomplete-dropdown-diagnoses li,
.autocomplete-dropdown-operating li {
  padding: 5px 4px;
  cursor: pointer;
  border-top: 1px solid #ccc;
}

.autocomplete-dropdown li:hover,
.autocomplete-dropdown-medicament li:hover,
.autocomplete-dropdown-diagnoses li:hover,
.autocomplete-dropdown-operating li:hover {
  background-color: #d0e4fa;
}

.autocomplete-dropdown-medicament {
  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 2000;
  max-height: 150px;
  overflow-y: auto;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.autocomplete-dropdown-diagnoses {
  position: absolute;
  top: 100%;
  left: 0;

  margin-left: 6.9%;
  width: 93.1%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 2;
  max-height: 150px;
  overflow-y: auto;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.autocomplete-dropdown-operating {
  position: absolute;
  top: 100%;
  left: 0;

  margin-left: 8.24%;
  width: 91.86%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 2000;
  max-height: 150px;
  overflow-y: auto;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.number-history-padding {
  padding-right: 4px;
}

.diagnoses-input {
  width: 100%;
}
.operating-input {
  width: 100%;
}

.medicament-input {
  max-width: 403px;
  min-width: 200px;
  width: 100%;
  min-height: 28px;
}

.notation-input {
  max-width: 510px;
  min-width: 250px;
  width: 100%;
  min-height: 28px;
}


.history-input {
  width: 100%;
}

.btns-save-table-container {
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  padding: 12px 0;
}

.btn-save-table {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 6px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.btn-save-table.blue {
  background-color: #c6dfff;
  border: 2px solid #76b0fc;

  font-weight: 500;
}

.btn-save-table.blue:hover {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.btn-save-table.green {
  background-color: #89e4b8a8;
  border: 2px solid #4fb483;
}

.btn-save-table.green:hover {
  background-color: #67d49e;
  color: #fdfdfd;
  border-color: #398863;
}


.custom-tooltip {
  font-size: 16px; /* или любой другой размер, который вам нужен */
}

.pagination-edit-reports {
  padding: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

@media (max-width: 1199.98px) {
  .pagination-edit-reports {
    padding: 20px 0 60px;
  }
}

.pagination-edit-reports .active {
  color: #1b7cfa;
  background-color: #f0f0f0;
}

.pagination-edit-reports .page-item {
  padding: 4px;
  cursor: pointer;
  border: 1px solid #000;
}

.pagination-edit-reports .previous,
.pagination-edit-reports .next {
  cursor: pointer;
}
