.btn-create {
  width: 120px;
  padding: 6px 20px;
  margin-top: 8px;
  background-color: #e0e0e0;
  border: 2px solid #afafaf;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}


.btn-create:hover {
  background-color: #ceefdf;
  border-color: #0aaf60;
  color: #0aaf60;
}
