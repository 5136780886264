.modal-create-user {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12%;
}

.modal-content-create-user {
  position: relative;
  width: 630px;

  text-align: center;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.modal-create-user-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modal-create-user-title {
  margin: 4px 0 16px;

  text-align: center;
  font-family: "Montserrat", sans-serif;

  font-weight: 600;
  font-size: 18px;
}

.modal-content-big-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal-content-group {
  display: flex;
  gap: 10px;
}

.modal-content-group input {
  width: 100%;
  padding: 6px 6px;
  border: 1px solid #949494;
  border-radius: 6px;
}

.modal-content-group.password input {
  width: 48.9%;
}

.modal-content-password {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 10px 0 10px 0;

  background-color: #dddddd;
  border-radius: 10px;
}



.modal-content-password input {
  padding: 6px 6px;
  border: 1px solid #949494;
  border-radius: 6px;
}

.modal-list-role-create {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;

  font-weight: 500;
  font-size: 16px;
}

.modal-list-role-checkbox {
  display: flex;
  gap: 2px;
}

.modal-list-role-checkbox input {
  width: 16px;
}

.modal-create-user-btn {
  margin: 0 auto;
  width: 120px;
  padding: 6px 20px;
  margin-top: 8px;
  background-color: #e0e0e0;
  border: 2px solid #afafaf;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.modal-create-user-btn:hover {
  background-color: #ceefdf;
  border-color: #0aaf60;
  color: #0aaf60;
}

.modal-edit-user-btn-password {
  width: 180px;
  margin-right: 14px;
  padding: 6px 20px;
  background-color: #fcfcfc;
  border: 2px solid #afafaf;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.modal-edit-user-btn-password:hover {
  background-color: #ceefdf;
  border-color: #0aaf60;
  color: #0aaf60;
}