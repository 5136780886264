.management-container {
  width: 100%;
}

.list-management-report {
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-top: 1px solid #000;
  padding-top: 28px;
}

.item-management-report {
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.item-management-report input,
.item-management-report select {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.select-value-page {
  height: 30px;
  width: 60px;
  padding-left: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.select-value-page option {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.select-value-type {
  height: 30px;
  width: 200px;
  padding-left: 4px;

}

.select-value-type option{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.select-sort-reports {
  height: 30px;
  width: 200px;
  padding-left: 4px;
}

.select-sort-reports option{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.input-size-filter {
  padding-left: 4px;
  width: 200px;
}

@media (min-width: 1200px) {
  .input-size-filter {
    height: 30px;
  }
}

@media (max-width: 1199.98px) {
  .input-size-filter {
    height: 40px;
  }
}

.container-dropdown-filter {
  position: relative;
  width: 200px;
}

.dropdown-filter {
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 2;
  max-height: 150px;
  overflow-y: auto;
}

.dropdown-filter li {
  padding: 5px 4px;
  cursor: pointer;
  border-top: 1px solid #ccc;
}

.dropdown-filter li:hover {
  background-color: #d0e4fa;
}

.input-size-filter {
  padding-left: 6px;
}

.item-management-search-patient {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item-management-search-user {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btn-search-patient-filter {
  width: 96px;
  cursor: pointer;
  padding: 2px 0 0 0;
  color: var(--blue-btns-color);
  background-color: var(--search-patients-btn-bg-color);
  border: 2px solid #72aefd;
  border-radius: 6px;
}

.btn-search-patient-filter:hover,
.btn-search-patient-filter:focus {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}


.btn-clear-patient-filter {
  width: 96px;
  cursor: pointer;
  padding: 2px 0 0 0;
  color: #464545;
  background-color: #e6e6e6;
  border: 2px solid #616161;
  border-radius: 6px;
}

.btn-clear-patient-filter:hover,
.btn-clear-patient-filter:focus {
  color: #fff;
  background-color: #fdb6b6;
  border-color:#f73939;
}

.input-patient-fullname-filter {
  min-height: 48px;
  width: 200px;
  border: 1px solid #000;
  padding: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .input-patient-fullname-filter {
    min-width: 300px;
  }
}