.chat-container {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  max-height: 100vw;
  background-color: #fff;
}

@media (min-width: 1200px) {
  .chat-container {
    padding: 0 10px;
    margin: 20px auto 0;
  }
}

@media (max-width: 1199.98px) {
  .chat-container {
    padding: 0 2px;
    margin: 12px auto 0;
  }
}

.select-bg-chat {
  display: flex;
  margin-left: auto;
  padding: 0 0 12px 0;
}

/* Стилизация основного скроллбара */
.message-list::-webkit-scrollbar {
  width: 10px;
  padding-right: 8px; /* Ширина скроллбара */
}

/* Стилизация "ползунка" скроллбара (бара) */
.message-list::-webkit-scrollbar-thumb {
  background-color: #dadadadc; /* Цвет "ползунка" */
  border-radius: 2px; /* Закругление углов "ползунка" */
}

/* Стилизация трека (фона) скроллбара */
.message-list::-webkit-scrollbar-track {
  background-color: #f1f1f123; /* Цвет трека */
}

.message-list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
}

@media (min-width: 1200px) {
  .message-list {
    max-height: 70vh;
    overflow-y: auto;
    padding: 4px 10px;
  }
}

@media (max-width: 1199.98px) {
  .message-list {
    max-height: 70vh;
    overflow-y: auto;
    padding: 4px 0;
  }
.message-list::-webkit-scrollbar {
    width: 4px; /* Ширина полосы */
  }
}

.background-chat {
  background-image: url(../../image/chat-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.background-select-chat {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.defoult-opt-bg-chat {
  color: #000000;
}

.my-message {
  padding: 4px 2px;
  text-align: right;
  border-radius: 6px;
  cursor: default;
  word-wrap: break-word;
}

.other-message {
  cursor: default;
  text-align: left;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 4px 6px 4px 6px;
}

.other-message .message-general-data {
  text-align: right;
}

.my-message .message-general-data {
  text-align: left;
}

.cont-chat-info-sms {
  width: 70%;
  max-width: 400px;
  padding: 10px;
  border-radius: 10px;
}

.my-message .cont-chat-info-sms {
  margin-left: auto;
  background-color: #5490ffdc;
}

.other-message .cont-chat-info-sms {
  margin-right: auto;
  background-color: #ffffffe8;
}

.message-general-data {
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
}

.cont-chat-info-name {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.cont-chat-info-text {
  color: #070707;
}

.date-header-group {
  margin: 0 auto;
  background-color: #ffffffa9;
  padding: 4px 8px;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-weight: 500;
  font-size: 14px;

  cursor: default;
}

.input-area-sms-general {
  position: relative;
  padding: 8px;
}


@media (max-width: 1199.98px) {
  .input-area-sms-general {
    padding: 8px 6px;
   
  }
}


.input-message {
  width: 100%;
  min-height: 46px;
  padding: 2px 80px 0 4px;
  
  border: 1px solid #dbdbdb;
  font-size: 16px;
  background-color: #ffffffc2;
  resize: none;
  border-radius: 6px;
}

.input-message::-webkit-scrollbar {
  width: 10px;
  padding-right: 8px; /* Ширина скроллбара */
}

/* Стилизация "ползунка" скроллбара (бара) */
.input-message::-webkit-scrollbar-thumb {
  background-color: #dadadadc; /* Цвет "ползунка" */
  border-radius: 2px; /* Закругление углов "ползунка" */
}

/* Стилизация трека (фона) скроллбара */
.input-message::-webkit-scrollbar-track {
  background-color: #f1f1f123; /* Цвет трека */
}

.input-message:focus {
  outline: 0;
  outline-offset: 0;
  border: 1px solid #646464;
}

.input-message::placeholder {
  color: #3b3b3b;
}

.icons-emoji {
  position: absolute;
  top: 12px;
  right: 52px;
  cursor: pointer;
  color: #181818;
}

@media (max-width: 1199.98px) {
  .icons-emoji {
    right: 46px;
  }
}

.icons-send {
  position: absolute;
  right: 16px;
  top: 12px;
  cursor: pointer;
  color: #181818;
}

@media (max-width: 1199.98px) {
  .icons-send {
    right: 10px;
  }
}


.icons-send:hover,
.icons-send:focus,
.icons-emoji:hover,
.icons-emoji:focus {
  color: #ffffffcc;
}

.gray-icon {
  color: rgb(87, 87, 87);
}

.emoji-container-general {
  position: absolute;
  bottom: 100%; /* Располагаем над элементами */
  right: 10px;
  z-index: 1000; /* Устанавливаем высокий z-index, чтобы контейнер был поверх других элементов */
}

@media (max-width: 1199.98px) {
  .emoji-container-general {
    right: 0px;
  }
}
