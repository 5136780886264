.consultation-table {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  padding: 52px 15px 8px 0;
  transform: translateY(0);
  opacity: 1;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}

.consultation-table-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  background-color: rgb(211, 211, 211);
}

.consultation-table-my-fullname,
.consultation-table-my-date {
  padding: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.consultation-table-my-date {
  text-align: center;
}

.consultation-table-size {
  width: 10%;
}

.consultation-table-size1 {
  width: 2%;
}

.consultation-table-size2 {
  width: 8.2%;
  text-align: center;
}

.consultation-table-size3 {
  width: 26%;
  text-align: center;
}

.consultation-table-size4 {
  width: 6%;
}

.consultation-table-size5 {
  width: 4%;
  text-align: center;
}

.consultation-table-size6 {
  width: 8%;
  text-align: center;
}

.consultation-table-patient-st {
  text-align: center;
}

.consultation-table-receipt-number {
  text-align: center;
}

.consultation-table-semi-title {
  padding: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  background-color: rgb(236, 236, 236);
}

.consultation-table-text {
  text-align: center;
}

.consultation-table-text-info {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.table-cons-discount {
  width: 100%;
  height: 36px;
  text-align: center;
}

.table-cons-receipt-number {
  width: 100%;
  height: 36px;
}

.table-cons-notation {
  width: 100%;
  height: 36px;
  max-width: 295.8px;
  min-width: 200px;
  min-height: 30px;
}

.table-cons-btns-manag-rows {
  display: flex;
  gap: 2px;
}

.table-cons-btn {
  padding: 4px 6px;
  background-color: var(--control-btns-bg-color);
  cursor: pointer;
  border: 2px solid var(--btns-brdr-color);
  border-radius: 4px;
}

.table-cons-btn.blue:hover,
.table-cons-btn.blue:focus {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.table-cons-btn.red:hover,
.table-cons-btn.red:focus {
  color: var(--white-btns-color);
  background-color: var(--del-btn-hover-bg-color);
  border: 2px solid #971212;
}

.checkbox-isfree {
  margin: 0 auto;
  border: 2px solid #aaaaaa;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.btn-patient.disabled,
.table-cons-btn.disabled,
.form1-btn-add.disabled,
.form1-btn-save.disabled {
  color: #f5f5f5;
  background-color: #d4d4d4;
  border-color: #afafaf;
  cursor: default;
}

.btn-patient.disabled:hover,
.table-cons-btn.disabled:hover,
.form1-btn-add.disabled:hover,
.form1-btn-save.disabled:hover  {
  color: #f5f5f5;
  background-color: #d4d4d4;
  border-color: #afafaf;
}

.checkbox-isfree.disabled i {
  color: #c5c5c5;

}

.form1-icon.disabled {
  color: #ff2222;
}

.consultation-table-text-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.consultation-table-text-group p:nth-child(1) {
  padding-bottom: 2px;
text-align: center;
  border-bottom: 1px solid #000;
}

.consultation-table-text-group p:nth-child(2) {
  padding-top: 2px;
}