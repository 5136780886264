.detailed-table-data {
  padding-top: 12px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-in-out;
}

.detailed-table-data.active {
  max-height: 100%;
}

.item-formset-table {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.item-formset-table th {
  padding: 8px 8px;
}

.item-formset-table td {
  padding: 4px 4px;
}

.size-table-formset {
  width: 12%;
}

.semititle-size2 {
  width: 12%;
}

.text-semititle3 {
  width: 30%;
}

.size-table-formset p {
  padding-left: 2px;
}

.size-table-formset6.yellow {
  background-color: rgb(255, 248, 152);
}

.size-table-formset6.green {
  background-color: rgb(135, 211, 182);
}

.size-table-formset1 {
  width: 10%;
}

.size-table-formset2 {
  width: 6.5%;
}

.size-table-formset3 {
  width: 4.7%;
}

.size-table-formset4 {
  width: 5%;
}

.size-table-formset5 {
  width: 9.2%;
}

.size-table-formset6 {
  width: 1%;
}

.size-table-formset7 {
  text-align: center;
  font-weight: 600;
}

.item-formset-table .text-size {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.static-checkmark-formset {
  display: flex;
  justify-content: center;
}

.rotate-0 {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.custom-checkbox {
  display: flex;
  justify-content: center;
}

.hidden-checkbox {
  display: none;
}

.custom-checkbox-label {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox-label .bx {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.custom-checkbox-label .bx:not(.invisible) {
  visibility: visible;
}

.btns-table-formset {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 0 12px;
}

.btn-save-table-formset {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 6px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;

  background-color: #c4eee0;
  border: 2px solid #78d4a9;
  font-size: 14px;
}
.btn-save-table-formset:hover {
  color: #fff;
  background-color: #5ac5a1;
  border-color: #3da474;
}

.text-semititle {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.text-semititle td {
  padding: 4px;
}

.semi-head {
  background-color: #dfdfdf;
}

.semi-head td {
  text-align: center;
}
