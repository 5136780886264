.modal-edit-user {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 12%;
  }
  
  .modal-content-edit-user {
    position: relative;
    width: 630px;
  
    text-align: center;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: #f5f5f5;
  }