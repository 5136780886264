.user-info-container {
  padding: 6px;

  background-color: #ececec;
  border: 2px solid #818181;
  border-radius: 6px;
}

.user-info-container td {
  padding: 4px;
}

.user-info-table-size2 {
  width: 25%;
}

.user-info-table-size3 {
  width: 14%;
  text-align: center;
}

.user-info-table-size4 {
  width: 4%;
  cursor: pointer;
}

.user-info-table-size4 div {
  display: flex;
  justify-content: center;
}

.checkbox-ban:hover {
  color: red;
}

.user-info-table-size5 {
  width: 13.2%;
}

.user-info-table-size6 {
  width: 3.7%;
  cursor: pointer;
}

.user-info-table-size6:hover {
  background-color: #dddddd;
}

.user-info-table-size6 div {
  display: flex;
  justify-content: center;
}

.btn-user-edit {
  width: 150px;
  padding: 6px;
  background-color: #e0e0e0;
  border: 2px solid #afafaf;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.btn-user-edit:hover {
  background-color: #c3ddff;
  border-color: #3981f7;
  color: #1a6ff8;
}

.users-container {
  max-width: 1700px;
  margin: 0 auto;
  padding: 28px 0px 0;
  background-color: #f7fafda2;
  border-radius: 6px;
}

.big-container-users-management {
  display: flex;
  gap: 8px;
}

.users-list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 0 0 40px 0;
}

.user-info-table {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.online-user {
  color: #029c52;
}

.ofline-user {
  color: #a3a3a3;
}

.user-info-table-size8 {
  width: 3%;
  text-align: center;
}

.user-info-table-size8 div{
  min-width: 12px;
}

.title-users {
  margin: 0 auto;
  width: 300px;
  padding: 0 0 8px;
  margin-bottom: 62px;
  border-bottom: 2px solid #000000;
}

.container-btn-create-new-users {
  margin-left: auto;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  width: 268px;
  border-bottom: 2px solid #000000;
  margin-bottom: 20px;
}

.admin-settings-icons i {
  font-size: 30px;
  cursor: pointer;
}

.admin-settings-icons i:hover {
  color: #5683a1;
}

.create-new-user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 200px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;

  cursor: pointer;
  background-color: var(--green-btns-bg-color);
  color: var(--save-btns-color);
  border: 2px solid var(--save-btns-color);
  border-radius: 6px;

  box-shadow: -1px 1px 3px 1px #e0e0e0;
}

.create-new-user:hover,
.create-new-user:focus {
  background-color: var(--save-btn-hover-bg-color);
  color: var(--white-btns-color);
  border: 2px solid #008545;
}

.roles-dropdown {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.roles-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
}

.roles-dropdown li:last-child {
  border-bottom: none;
}

.roles-dropdown li:hover {
  background-color: #ddd;
}

.bx-sm {
  transition: transform 0.3s ease-in-out;
}

.rotated-full-info-user {
  transform: rotate(180deg);
}

.checkbox-clicked,
.checkbox-clicked .user-info-table {
  background-color: #e4e4e4;
}

.user-info-table {
  background-color: #ffffff;
}

.user-details-table {
  margin-top: 4px;
}

.user-details-tbody td {
  padding: 8px;
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.user-details-size {
  width: 42%;
}

.users-management-container {
  max-width: 1200px;
  flex: 1;
  padding: 0 0 100px 0;
}

.users-sorting-container {
  padding: 78px 0 0;
  width: 230px;
}

.user-sorting-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.user-sorting-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.select-value-sort {
  width: 110px;
  height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding-left: 4px;
}

.select-value-sort option {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.inp-sort input {
  width: 200px;
  height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding-left: 4px;
}

.users-sorting-btn {
  margin-top: 20px;
}

.users-sorting-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 200px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;

  cursor: pointer;
  background-color: var(--green-btns-bg-color);
  background-color: #c6dfff;
  border: 2px solid #72aefd;
  border-radius: 6px;

  box-shadow: -1px 1px 3px 1px #e0e0e0;
}

.users-sorting-btn:hover,
.users-sorting-btn:focus {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}
