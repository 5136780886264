.modal-create-news {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-create-news-content {
  display: flex;
  flex-direction: column;
}

.create-news-content {
  min-width: 300px;
  position: relative;
  padding: 20px 20px 24px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  background-color: #f5f5f5;
}

@media (max-width: 1199.98px) {
  .create-news-content {
    padding: 20px 10px 24px;
    margin: 0 4px;
  }
}

.bold-font-message {
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}

.btn-close-modal-create-news {
  position: absolute;
  right: 4px;
  top: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  cursor: pointer;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
}

.btn-close-modal-create-news:hover {
  background-color: #e7e7e7;
  border: 2px solid #afafaf;
}

.btn-close-modal-create-news img {
  width: 20px;
  height: 20px;
}

.form-create-news-message {
  min-height: 120px;
  min-width: 400px;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #949494;
}

@media (max-width: 1199.98px) {
  .form-create-news-message {
    min-width: 100%;
    max-width: 100%;
    max-height: 400px;
  }
}

.form-create-news-title {
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #949494;
  margin-bottom: 8px;
}

@media (max-width: 1199.98px) {
  .form-create-news-title {
    min-width: 100%;
    max-width: 100%;
    min-height: 34px;
    max-height: 100px;
  }
}

.news-container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 20px 15px 80px;

  display: flex;
  flex-direction: column;
  background-color: #e8eef756;
}

@media (max-width: 1199.98px) {
  .news-container {
    padding: 20px 10px 80px;
  }
}

.cls-button {
  background-color: transparent;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .container-btn-create-news {
    padding: 0 0 12px 0;
    margin-left: auto;
    border-bottom: 2px solid #597193;
  }
}

@media (max-width: 1199.98px) {
  .container-btn-create-news {
    position: fixed;
    top: 80vh;
    right: 10px;
  }
}

.btn-form-create-news {
  margin: 0 auto;
  padding: 6px 20px;
  margin-top: 12px;
  background-color: #e0e0e0;
  border: 2px solid #afafaf;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.btn-form-create-news:hover {
  background-color: #ceefdf;
  border-color: #0aaf60;
  color: #0aaf60;
}

.name-modal-create-news {
  margin: 4px 0 16px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.btn-create-news {
  margin: 0 auto;
  height: 40px;
  width: 180px;

  color: #fdfdfd;
  background-color: #59719386;
  border: none;
  box-shadow: 2px 2px 4px #d4d4d4ad;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.btn-create-news:hover {
  background-color: #599393b9;
  color: #fdfdfd;
}

@media (max-width: 1199.98px) {
  .btn-create-news {
    display: none;
  }
}

.btn-create-news-mob {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid #607692b0;
  background-color: #f8f8f89f;
  color: #000000b9;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 40px;
  cursor: pointer;
  box-shadow: 0 0 6px 1px rgba(133, 133, 133, 0.678);
}

.btn-create-news-mob:hover,
.btn-create-news-mob:focus {
  color: #fff;
  background-color: #87d3b675;
}

@media (min-width: 1200px) {
  .btn-create-news-mob {
    display: none;
  }
}

.message-list-information {
  display: flex;
  flex-direction: column;

  flex-direction: column-reverse;
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .message-list-information {
    width: 780px;
    padding: 20px;
    gap: 28px;
    margin: 0 auto;
  }
}

@media (max-width: 1199.98px) {
  .message-list-information {
    gap: 12px;
  }
}

.item-message-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3e7bcf21;
  padding: 20px 20px 30px;
  border-radius: 6px;
}

@media (max-width: 1199.98px) {
  .item-message-information {
    padding: 10px 10px 15px;
    flex: 1;
  }
}

.item-message-information h2 {
  font-family: "Comfortaa", sans-serif;
  font-size: 20px;
  padding: 0 0 12px;
}

.message-information-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}

.message-information-text-up {
  padding: 12px 0 0 0;
  display: flex;
  min-width: 100%;
}

.message-information-data {
  color: #002355da;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.message-information-user {
  margin-left: auto;
  color: #002355da;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .message-information-user {
    font-weight: 700;
    font-size: 12px;
  }

  .message-information-data {
    font-weight: 600;
    font-size: 12px;
  }
}

/* Rubik Montserrat Comfortaa Open Sans  Raleway Ubuntu Roboto Mono */

.modal-edit-news {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-news-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.delete-confirmation-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;

  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}

.delete-confirmation-modal p {
  font-size: 18px;
  margin-bottom: 12px;
}

.delete-confirmation-modal button {
  width: 120px;
  padding: 6px 20px;
  margin-top: 8px;
  background-color: #e0e0e0;
  border: 2px solid #afafaf;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.delete-confirmation-modal button:nth-child(1):hover {
  background-color: #ceefdf;
  border-color: #0aaf60;
  color: #0aaf60;
}

.delete-confirmation-modal button:nth-child(2):hover {
  background-color: #ffc3c3;
  border-color: #f73939;
  color: #f81a1a;
}

.btn-modal-dlt-news {
  display: flex;
  gap: 12px;
}

.btns-management-news-cont {
  margin-left: auto;
  padding: 30px 0 10px 0;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #000b2ea9;
}

.btn-management-news {
  margin: 0 auto;
  height: 32px;
  width: 120px;

  color: #fdfdfd;
  background-color: #59719386;
  border: none;
  box-shadow: 2px 2px 4px #d4d4d4ad;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
}

@media (max-width: 1199.98px) {
  .btn-management-news {
    display: none;
  }
}

.btns-management-news-cont button:nth-child(1):hover {
  background-color: #599393b9;
  color: #fdfdfd;
}

.btns-management-news-cont button:nth-child(2):hover {
  background-color: #935959b9;
  color: #fdfdfd;
}

.btn-management-news-mob {
  height: 40px;
  width: 60px;
  color: #fdfdfd;
  background-color: #59719386;
  border: none;
  box-shadow: 2px 2px 4px #d4d4d4ad;

  cursor: pointer;
  border-radius: 6px;
}

.btns-management-news-cont button:nth-child(3):hover {
  background-color: #599393b9;
  color: #fdfdfd;
}

.btns-management-news-cont button:nth-child(4):hover {
  background-color: #935959b9;
  color: #fdfdfd;
}

@media (min-width: 1200px) {
  .btn-management-news-mob {
    display: none;
  }
}
