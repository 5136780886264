.react-datepicker {
  width: 280px;
  height: 310px;
  border: 3px solid #b1b1b1;
  /* margin-left: 100px; */
  font-size: 1em;
}

.react-datepicker__current-month {
  font-size: 1.1em;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 12px;
}

.react-datepicker__month-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container input {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  height: 30px;
  width: 200px;
  padding-left: 5px;
}

@media (max-width: 1199.98px) {
  .react-datepicker__input-container input {
    height: 40px;
  }
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 6px 6px;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker__day {
  user-select: none;
}

.react-datepicker__navigation--previous {
  top: 6px;
  left: 10px;
}

.react-datepicker__navigation--next {
  top: 6px;
  right: 10px;
}

.react-datepicker__navigation-icon--next {
  border-color: #000000;
}
