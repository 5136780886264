.auth-container {
  max-width: 1200px;
  margin: 12% auto 0;
  padding: 2vw 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-autorization {
  width: 120px;
  margin: 0 auto;
}

.main-title {
  margin-top: 20px;
  color: #7290bb;
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 3px;
}

.main-text {
  margin-top: 16px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #7c7c7c;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 4px;
}

.autorization-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;
  background-color: #fff;
  border: 2px solid #cacace;
  border-radius: 6px;
}

@media (min-width: 1200px) {
  .autorization-container {
    min-width: 500px;
    padding: 32px 0 40px;
  }
}

@media (max-width: 1199.98px) {
  .autorization-container {
    min-width: 290px;
    padding: 32px 12px 40px;
  }
}

.autorization-form {
  display: flex;
  flex-direction: column;
}

.input-1 {
  margin: 12px auto;
}

.form-input {
  min-height: 48px;
  padding: 0 16px;
  border: 1px solid #cacace;
  border-radius: 12px;
  box-shadow: 0 1px 4px rgba(0,0,0,.15);
}

@media (min-width: 1200px) {
  .form-input {
    min-width: 260px;
  }
}

@media (max-width: 1199.98px) {
  .form-input {
    min-width: 250px;
  }
}

.form-input::placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #7c7c7c;
}

.autorization-btn {
  margin: 24px auto 36px;
  background-color: #f1f1f1;
  border: 2px solid #cacace;
  border-radius: 12px;
  cursor: pointer;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}


@media (min-width: 1200px) {
  .autorization-btn {
    min-width: 260px;
    height: 48px;
    font-size: 16px;
  }
}

@media (max-width: 1199.98px) {
  .autorization-btn {
    min-width: 40%;
    height: 50px;
    font-size: 14px;
  }
}

.autorization-btn:hover,
.autorization-btn:focus {
  background-color: #ceefdf;
  color: #0aaf60;
  border: 2px solid #ceefdf;
}

.password-input-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.err-auth {
  color: red;
  white-space: pre-wrap;
}

.autorization-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
}
