body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  background-color: #fafafa;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

body {
  overflow-y: scroll;
  overflow-x: hidden; /* hides horizontal scrollbar */
  margin-right: calc(-1 * (100vw - 100%));
}

/* html {
	overflow: scroll;
} */

h2 {
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  fill: currentColor;
}

.list,
ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root {
  /* color */
  --primary-color: #eeeeee;
  --secondary-color: #000000;

  --anest-title-color: #21a766;
  --oper-title-color: #ffbb00;
  --resus-title-color: #4753ff;
  --surg-title-color: #3981f7;

  --white-btns-color: #fff;
  --blue-btns-color: #3981f7;

  --save-btns-color: #0aaf60;

  --del-btn-hover-color: #f73939;
  --copy-btn-hover-color: #21a766;

  --locked-color: #cacace;
  --locked-btn-color: #ff4040;
  --locked-btns-control-color: #b9b9b9;

  --required-color: rgb(255, 97, 97);

  /* background color */
  --primary-bg-color: #eeeeee;
  --secondary-bg-color: #3981f7;

  --anest-title-bg-color: #d7f7e7;
  --oper-title-bg-color: #fff7c7;
  --resus-title-bg-color: #e6e7ff;
  --surg-title-bg-color: #dfe9fa;

  --green-btns-bg-color: #ceefdf;
  --search-patients-btn-bg-color: #cadeff;
  --control-btns-bg-color: #fff;
  --save-btn-hover-bg-color: #6bd1a0;
  --del-btn-hover-bg-color: #ff5e5e;
  --invalid-forms-bg-color: #ffe6e6;

  /* border color */
  --btns-brdr-color: #cacace;
}

/** ----- ФОРМИ -----  */
.form1-icon {
  cursor: pointer;
}

.form1-icon:hover,
.form1-icon:focus {
  color: var(--blue-btns-color);
}

.form1-delete:hover,
.form1-delete:focus {
  color: var(--del-btn-hover-color);
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #777777;
  padding: 4px;
}

td {
  border: 1px solid #777777;
  padding: 6px;
}

th {
  background-color: var(--primary-bg-color);
}

.list-day {
  width: 100%;
  padding: 8px;
}

.form-table-name-user {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  padding: 4px;
}

.form1-table-text-name {
  padding: 8px;
  width: 100%;
  height: 100%;
  min-height: 30px;
}

.form1-table-text-name.notes {
  min-width: 200px;
  max-width: 346.6px;
  height: 40px;
}

.form1-table-text-name.medicaments {
  min-width: 200px;
  max-width: 380.6px;
  height: 40px;
}

.table1-btn {
  padding: 4px 8px;
  background-color: var(--control-btns-bg-color);
  cursor: pointer;
  border: 2px solid var(--btns-brdr-color);
  border-radius: 4px;
}

.table1-btn.blue:hover,
.table1-btn.blue:focus {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.table1-btn.red:hover,
.table1-btn.red:focus {
  color: var(--white-btns-color);
  background-color: var(--del-btn-hover-bg-color);
  border: 2px solid #971212;
}

.form1-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.form1-btn-add {
  display: flex;
  padding: 6px;
  margin-left: 8px;

  height: 40px;
  cursor: pointer;
  background-color: var(--primary-bg-color);
  border: 2px solid var(--btns-brdr-color);
  border-radius: 120px;
}

.form1-btn-add:hover,
.form1-btn-add:focus {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.form1-btn-save {
  height: 40px;
  min-width: 120px;
  background-color: var(--green-btns-bg-color);
  color: var(--save-btns-color);
  border: 2px solid var(--save-btns-color);
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.form1-btn-save:hover,
.form1-btn-save:focus {
  background-color: var(--save-btn-hover-bg-color);
  color: var(--white-btns-color);
  border: 2px solid #008545;
}

.form2-icons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
  gap: 8px;
}

.app-main {
  background-color: #fcfcfc00;
}

.form2-table {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  padding: 30px 0 30px 0;
  transform: translateY(0);
  opacity: 1;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}

.form2-table-size {
  width: 11%;
}

.form2-table-size1 {
  width: 30%;
}

.form2-table-size2 {
  width: 10%;
}
.form2-table-size3 {
  width: 8%;
}

.form2-table-size4 {
  width: 8%;
}

.form1-table-title {
  background-color: var(--surg-title-bg-color);
  color: var(--surg-title-color);

  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
}

.form2-table-title {
  background-color: var(--anest-title-bg-color);
  color: var(--anest-title-color);

  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
}

.form3-table-title {
  background-color: var(--oper-title-bg-color);
  color: var(--oper-title-color);

  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
}

.form4-table-title {
  background-color: var(--resus-title-bg-color);
  color: var(--resus-title-color);

  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
}

.form2-table-time {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.form2-table-column1-text {
  display: flex;
  justify-content: flex-end;

  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.form2-table-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.form1-table-number {
  padding: 8px;
  width: 100%;
}

.form1-table-text-name {
  padding: 8px;
  width: 100%;
}

.form2-table-year {
  display: flex;
  justify-content: flex-end;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.form2-table2 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  background-color: var(--primary-bg-color);
}

.form2-new-row td:nth-child(1) {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
}

.add-new-form2 {
  margin: 40px auto;
  max-width: 1200px;
  display: flex;
  padding: 16px;
  /* align-items: center;
  justify-content: center; */

  height: 60px;
  width: 60px;

  cursor: pointer;
  background-color: var(--primary-bg-color);
  border: 2px solid var(--btns-brdr-color);
  border-radius: 100px;
}

.add-new-form2:hover,
.add-new-form2:focus {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.form2-table.locked .form1-delete {
  pointer-events: none;
  opacity: 0.5;
  color: var(--locked-btns-control-color);
  cursor: default;
}

.form2-table.locked .form1-copy {
  pointer-events: none;
  opacity: 0.5;
  color: var(--locked-btns-control-color);
  cursor: default;
}

.form2-table.locked .form1-icon {
  color: var(--locked-btn-color);
}

.form1-copy:hover,
.form1-copy:focus {
  color: var(--copy-btn-hover-color);
  cursor: pointer;
}

.form2-table.locked .form1-btn-save {
  background-color: var(--primary-bg-color);
  color: var(--locked-color);
  border: 2px solid var(--locked-color);
  cursor: default;
}

.form1-table-text-name.patient {
  margin-right: 12px;
}

.btns-patient {
  display: flex;
  gap: 4px;
}

.delete-button1 {
  margin-right: 8px;
}

.table1-btn.blue {
  margin: 0 10px 0 10 px;
}

.btn-patient {
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
}

.btn-patient.blue {
  padding: 2px 6px 0;
  color: var(--blue-btns-color);
  background-color: var(--search-patients-btn-bg-color);
  border: 2px solid var(--blue-btns-color);
}

.btn-patient.green {
  display: flex;
  padding: 4px 6px;
  background-color: var(--green-btns-bg-color);
  color: var(--save-btns-color);
  border: 2px solid var(--save-btns-color);
}

.btn-patient.inactive {
  display: flex;
  padding: 4px 6px;
  background-color: var(--primary-bg-color);
  color: var(--locked-color);
  border: 2px solid var(--locked-color);
  cursor: default;
}

.btn-patient.blue:hover,
.btn-patient.blue:focus {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.btn-patient.green:hover,
.btn-patient.green:focus {
  background-color: var(--save-btn-hover-bg-color);
  color: var(--white-btns-color);
  border: 2px solid #008545;
}

.form2-table.locked .btn-patient {
  background-color: var(--primary-bg-color);
  color: var(--locked-color);
  border: 2px solid var(--locked-color);
  cursor: default;
}

.form2-table.locked .form1-btn-add {
  background-color: var(--primary-bg-color);
  color: var(--locked-color);
  border: 2px solid var(--locked-color);
  cursor: default;
}

.invalid {
  border: 1px solid red;
  background-color: var(--invalid-forms-bg-color);
}

.required {
  color: var(--required-color);
}

.form2-table.slide-out {
  transform: translateY(-100%);
  opacity: 0;
}

.fade-in {
  opacity: 0;
  transition: opacity 500ms;
  animation: fadeInAnimation 500ms forwards;
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}

.form2-table.locked .table1-btn {
  background-color: var(--primary-bg-color);
  color: var(--locked-color);
  border: 2px solid var(--locked-color);
  cursor: default;
}

.btn-row div {
  display: flex;
  justify-content: center;
  gap: 8px;
}
