.container-data-editing {
  max-width: 1200px;
  padding: 30px 0px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.data-editing-title-patient {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #020202;
}

.data-editing-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #020202;
  padding-right: 20px;
}

.data-editing-patient-container {
  margin-top: 16px;
  padding: 20px 20px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  background-color: #eaf4f6de;
  border-radius: 20px;
}

.data-editing-patient-semi-container {
  width: 400px;
  padding: 10px 0 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  border-bottom: 2px solid #4c6487a8;
}

.data-editing-patient-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #020202;
}

.form-patient-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-patient-semi-container {
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  padding-bottom: 40px;
}

.form-patient-editing.form1,
.form-patient-editing.form2,
.form-patient-editing.form3 {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 16px;
}
.form-patient-editing.form1 div,
.form-patient-editing.form2 div,
.form-patient-editing.form3 div {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.form-patient-editing label {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.form-patient-editing input {
  height: 30px;
  padding: 4px;

  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #4c648785;
  border-radius: 6px;
}

.form-patient-editing-input {
  width: 100%;
}

.form-patient-editing-birthday {
  width: 152px;
}

.btn-data-editing-patient {
  cursor: pointer;
  width: 100px;
  height: 34px;
  padding: 4px 0 2px;
  color: #4c6487;
  background-color: #c9d6e9;
  border: none;
  border-radius: 20px;
  box-shadow: 2px 2px 4px #d4d4d4ad;
}

.btn-data-editing-patient:hover,
.btn-data-editing-patient:focus {
  background-color: #b9cae0;
  border: none;
}

.form-patient-editing-btn {
  margin: 0 auto;
  height: 40px;
  width: 180px;

  color: #fdfdfd;
  background-color: #59719386;
  border: none;
  box-shadow: 2px 2px 4px #d4d4d4ad;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.form-patient-editing-btn:hover {
  background-color: #599393b9;
  color: #fdfdfd;
}

.editing-semi-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
}

.editing-container-items {
  background-color: #eaf6f0da;
  border-radius: 20px;
  padding: 20px 0;
}

.editing-item-inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 30px 30px 30px 30px;
  border-left: 2px solid #4c877d85;
}

.editing-item-inputs input {
  width: 560px;
}

.editing-diagnoses-inputs div {
  display: flex;
  align-items: center;
}

.autocomplete-container-edit-data {
  position: relative;
  display: flex;
  align-items: center;
}

.autocomplete-dropdown-edit-data li {
  padding: 5px 10px;
  cursor: pointer;
  border-top: 1px solid #4c877d85;
}

.autocomplete-dropdown-edit-data li:hover {
  background-color: #d0e4fa;
}

.autocomplete-dropdown-edit-data {
  position: absolute;
  top: 100%;
  left: 70px;

  width: 64.2%;
  border: 1px solid #4c877d85;
  border-radius: 6px;
  background-color: #fff;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;

  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.editing-semi-container label {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.editing-semi-container input {
  height: 30px;
  padding: 4px 8px;

  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #4c877d85;
  border-radius: 6px;
}

.btn-editing-data-item {
  margin-left: 12px;
  height: 30px;
  width: 180px;

  color: #fdfdfd;
  background-color: #59719386;
  border: none;
  box-shadow: 2px 2px 4px #d4d4d4ad;

  cursor: pointer;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.btn-editing-data-item:hover {
  background-color: #4c877d85;
  color: #fdfdfd;
}
