.container-marks-forms {
  margin: 0 auto;
  max-width: 1700px;
  display: flex;
  gap: 4px;
}

.calendar-marks-forms {
  margin: 0 auto;
  display: flex;
  padding-top: 80px;
  flex-direction: column;
  align-items: center;
}

.mini-table-marks {
  max-width: 1180px;
  margin: 30px 0 0 10px;
  height: 30px;
}

.container-marks-forms-size {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1200px;
  padding: 0 0 100px 0;
}

@media (max-width: 1199.98px) {
  .container-marks-forms-size {
    padding: 0 0 4px 0;
  }
}

.list-formset {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 20px 0 30px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.item-formset {
  display: flex;
  flex-direction: column;
  padding: 8px;

  border: 2px solid #b1b1b1;
  border-radius: 10px;
}

.container-pharmacy-forms td {
  padding: 4px 8px;
}

.mini-table-marks-size {
  width: 1%;
}

.mini-table-marks-size1 {
  width: 9.1%;
}

.mini-table-marks-size2 {
  width: 12%;
}

.mini-table-marks-size3 {
  width: 10%;
}

.mini-table-marks-size4 {
  width: 12%;
}
