.cons-big-container {
  max-width: 1700px;
  padding: 40px 0;
  display: flex;
  gap: 4px;
}

.cons-sort-container {
  min-width: 230px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
/* @media (min-width: 1200px) {
  .cons-sort-container
  flex-direction: column;
} */

@media (max-width: 1199.98px) {
  .cons-sort-container {
    padding: 0 4px;
    /* align-items: center; */
    flex: 1;
    width: 100%;
  }
  /* .cons-sort-container li {
    display: flex;
  } */

  .cons-sort-container li:nth-child(2),
  .cons-sort-container li:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .cons-sort-container .cons-sort-container-number {
    padding-bottom: 20px;
    border-bottom: 2px solid;
  }
}

.select-sort-new-cons {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  width: 200px;
}

@media (max-width: 1199.98px) {
  .select-sort-new-cons {
    height: 40px;
  }
}

.cons-sort-container-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .cons-sort-container-title {
    margin-bottom: 8px;
  }
}

.cons-sort-container-search {
  padding-bottom: 8px;
}

@media (max-width: 1199.98px) {
  .cons-sort-container-search {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.cons-big-container-data {
  max-width: 1200px;
  flex: 1;
}

.cons-big-container-data-size {
  width: 6px;
}

.cons-big-container-data-size.yellow {
  background-color: rgb(255, 248, 152);
}

.cons-big-container-data-size.green {
  background-color: rgb(135, 211, 182);
}

.cons-big-container-data-size1 {
  width: 10%;
  text-align: center;
}

.cons-big-container-data-size1 span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.cons-big-container-data-size3 {
  width: 8%;
  text-align: center;
}
.cons-big-container-data-size6 {
  width: 6.6%;
  text-align: center;
}

.cons-big-container-data-size4 {
  width: 30%;
}

.cons-big-container-data-size4 span {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.cons-big-container-data-size5 {
  width: 8.2%;
}

.btns-maneg-patient-filter {
  display: flex;
  gap: 8px;
}

@media (max-width: 1199.98px) {
  .btns-maneg-patient-filter {
    padding-top: 4px;
  }
}

.cons-big-container-data-btns {
  display: flex;
  gap: 4px;
}

.cons-big-container-data-btns button {
  cursor: pointer;
  height: 36px;
  border-radius: 4px;
}

.cons-big-container-data-btns button:nth-child(2) {
  background-color: #c4eee0;
  border: 2px solid #78d4a9;
}

.cons-big-container-data-btns button:nth-child(2):hover {
  color: #fff;
  background-color: #5ac5a1;
  border-color: #318860;
}

.cons-big-container-data-btns button:nth-child(1) {
  background-color: #cadeff;
  border: 2px solid #72aefd;
}

.cons-big-container-data-btns button:nth-child(1):hover {
  color: #fff;
  background-color: #72aefd;
  border-color: #1b7eff;
}

.cons-big-container-data-btns.disabled button {
  cursor: default;
  color: #b9b9b9;
  background-color: #e7e7e7;
  border: 2px solid #a7a7a7;
}

.cons-big-container-data-btns.disabled button:hover {
  cursor: default;
  color: #b9b9b9;
  background-color: #e7e7e7;
  border: 2px solid #a7a7a7;
}

.cons-table-edit-rec-n,
.cons-table-edit-notation,
.cons-table-edit-discount {
  width: 100%;
  height: 36px;
}

.cons-table-edit-notation {
  max-width: 346.6px;
  min-width: 200px;
  min-height: 30px;
}

.cons-table-edit-discount {
  text-align: center;
}

.cons-table-edit-rec-n {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.cons-big-container-data-patient {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .cons-big-container-data-patient {
    font-size: 10px;
  }
}

.cons-big-container-data-patient div {
  text-align: center;
}

.cons-big-container-data-patient div:nth-child(1) {
  padding-bottom: 1px;
  border-bottom: 1px solid #000;
}

.cons-big-container-data-patient div:nth-child(2) {
  padding-top: 2px;
}

.cons-big-table-title {
  margin-bottom: 30px;
}

@media (max-width: 1199.98px) {
  .cons-big-table-title {
    font-size: 10px;
  }
  .cons-big-table-title td {
    padding: 4px 2px;
  }
}

.cons-big-container-title-date {
  margin-bottom: 10px;
  padding: 10px 0;

  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;

  background-color: rgb(202, 219, 233);
  border-radius: 6px;
}

.cons-big-container-data-isfree {
  margin: 0 auto;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.cons-big-container-data-isfree {
  height: 36px;
  width: 36px;
}

.cons-table-edit-discount-value {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
