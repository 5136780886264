.container-create-reports {
  max-width: 1200px;
  margin: 0 auto;
}

.forms-header {
  width: 310px;
  padding: 40px 0 6px;
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 2px solid #505050;
}

.list-create-reports {
  width: 190px;
  height: 30px;
  padding-left: 5px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 2px 1px 4px #dadada;

}

.item-create-reports {
  padding: 12px 0;
  border-radius: 6px;
}
