.diagnoses-dropdown {
    position: absolute; 
    max-height: 200px;  
    overflow-y: auto;   
    width: 47.88%;      
    border: 1px solid #ccc; 
    background-color: #fff; 
    z-index: 1000;      
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  }


  .diagnoses-dropdown li {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .diagnoses-dropdown li:hover {
    background-color: #e6e6e6;
  }